/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Image, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1blu4vu --style4 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30771/bbd36fb2537248b0b2cf3141538b3de8_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --anim-s5 --center el--1 flex--center" anim={"3"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --center pl--0" anim={"6"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box ff--1 fs--14 w--600 swpf--uppercase ls--50" content={"<span style=\"color: rgb(255, 255, 255); font-weight: normal;\">Když hledáte profesionála</span><br>"}>
              </Subtitle>

              <Title className="title-box ff--3 fs--86 w--500 lh--1" style={{"marginTop":19}} content={"<span style=\"color: rgb(255, 255, 255);\">Dj Negative</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"d6fdrtnlgje"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--48" content={"Dj na svatbu, ples, VIP akce"}>
              </Title>

              <Text className="text-box fs--16" content={"Kvalitní ozvučení a osvětlení Vaší svatby po celé ČR.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-q4x8gz --left pb--60 pt--80" name={"sluzby-2"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/30771/ae8a07897ba7454b98db534720d1406c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":517}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">Pop, Funk, Latino, Hip-Hop, House..&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box mb--40" style={{"maxWidth":"","paddingBottom":0}} content={"<span style=\"color: var(--color-dominant)\"><span style=\"font-weight: bold;\">Hudba přesně podle Vašeho přání</span><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"marginTop":0,"marginBottom":28}} content={"<span style=\"color: rgb(255, 255, 255);\">Proč právě Negative?</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--16 mb--40" style={{"maxWidth":765,"paddingRight":0}} content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">• Jeden z nejzkušenějších technických Djs působících na české scéně\n<br>• Klubovému DJingu se aktivně věnuje od roku 1996<br>•&nbsp;Otočený crossfader u jeho prvního mixpultu dal vzniknout pseudonymu Negative<br>• 1.místo v DJské soutěži IDA CZ/SK 2023&nbsp; <br>- účast na mistrovství světa v kategoriích Scratch a Technical<br>• Koncertní DJ kapel jako je např. Portless, Navigators, Stephunk T, Notes From Prague\n<br>• Mezi lety 2007 a 2012 moderoval vlastní pořad na rádiu Spin<br>• Bohaté zkušenosti jako svatební DJ, úzce spolupracuje se svatební kapelou Joybox (více jak 20 let existence)</span></span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"bkw71za8oth"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--48" content={"A co ode mne můžete očekávat?"}>
              </Title>

              <Text className="text-box fs--16" content={"Empatický svatební Dj se zkušenostmi, kterého hledáte. <br>Ke každé svatbě přistupuji individuálně a vybírám vhodné vybavení pro dané prostředí.<br><br>Zajistím:<br>•ozvučení obřadu <br>•hudební podkres při svatebních soutěžích, hostině<br>•k dispozici budou dva bezdrátové mikrofony<br>•sestavení party playlistu dle Vašich požadavků<br>•písničky na přání<br>•osvětlení tanečního parketu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/30771/8e40e461df284bb6aa1798f715fc9e1d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/30771/8e40e461df284bb6aa1798f715fc9e1d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30771/8e40e461df284bb6aa1798f715fc9e1d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30771/8e40e461df284bb6aa1798f715fc9e1d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-qzlsso pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/30771/df38f8a056fa405d8c0ebc584891c195_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white\">Těším se na Vás</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--18 swpf--uppercase ls--50" style={{"marginTop":0,"marginBottom":33}} content={"Dj Negative"}>
              </Subtitle>

              <ContactForm name={"8q9ecbge0y"} style={{"paddingTop":20}} action={"contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}